<template>
  <LargeLayout>
    <div
      class="card d-flex flex-column gap-3 border-0"
      v-for="(item, index) in properties"
      :key="index"
    >
      <Card
        :property="item"
        :id="item._id"
        :title="item.title"
        :image="item.images[0]"
        :plan="item.plan"
        :address="item.address"
        :cost="item.cost"
        :utility_cost="item.utility_costs"
        :wifi_cost="item.wifi_cost"
        :area="item.square"
        :bedroom="item.bedroom"
        :bathroom="item.bathroom"
        :neighborhood="item.condition[0]"
      />
    </div>
    <b-pagination
      align="center"
      v-model="page"
      :total-rows="count"
      :per-page="size"
      @change="handlePageChange"
      aria-controls="history-list"
    ></b-pagination>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import Card from "../../components/utils/Card.vue";
import { mapState } from "vuex";
import axiosIns from "../../libs/axiosConfig";
export default {
  components: {
    LargeLayout,
    Card,
  },
  data() {
    return {
      page: 1,
      properties: [],
      size: 6,
      count: 0,
      term: "",
    };
  },
  computed: {
    ...mapState(["search_result"]),
    query() {
      return this.$store.state.search_result;
    },
  },
  created() {
    // console.log(this.$store.state.search_result.properties)
    this.properties = this.$store.state.search_result.properties;
    this.count = this.$store.state.search_result.totalItems;
    this.term = this.$store.state.search_result.term;
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      axiosIns
        .post("/search?page=" + this.page + "&size=" + this.size, {
          content: this.term,
        })
        .then((res) => {
          this.properties = res.data.properties;
          this.count = res.data.totalItems;
        });
      this.term = "";
    },
  },
  watch: {
    query() {
      this.properties = this.$store.state.search_result.properties;
      this.count = this.$store.state.search_result.totalItems;
      this.term = this.$store.state.search_result.term;
    },
  },
};
</script>

<style></style>
